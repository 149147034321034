export const REDIRECT_TO_SENTRY_PROJECT_KEY = 'ROUTE_TO';

export const ALGOLIA_REQUEST_REGEX =
    /^https:\/\/CM7VJJA0AR-[\da-z]+\.algolia\.net\/1\/indexes\/\*\/queries$/u;

export const ERROR_IGNORE_LIST = [
    // Plugin/Extension errors
    'top.GLOBALS',
    "Blocked 'script' from 'about:'", // CSP

    // Paypal Zoid errors
    'zoid destroyed all components',
    'Detected container element removed from DOM',
    'Component closed',

    // Instagram errors
    "Can't find variable: _AutofillCallbackHandler",

    "undefined is not an object (evaluating 'window.webkit.messageHandlers.selectedTextHandler.postMessage')", // https://sentry.io/organizations/jsmdh/issues/1816768379/?project=1895708&query=is%3Aunresolved&sort=freq&statsPeriod=90d
    'Non-Error promise rejection captured with keys: currentTarget, detail, isTrusted, target',
    'Non-Error promise rejection captured with value: challenge-closed', // last-check captcha closed https://jsmdh.sentry.io/issues/6273460246/?project=1895708

    // PhantomJS errors
    "Expected an identifier but found 're' instead",

    // Firefox errors
    'can\'t redefine non-configurable property "userAgent"',
    "undefined is not an object (evaluating 'Object.keys(this.purposes)')",
    '`beforeSend` returned `null`, will not send event.',

    // Miscellaneous errors
    /Timeout exceeded/iu,
    /Network Error/iu,
    /Request aborted/iu,
    'NetworkError when attempting to fetch resource',
    /Loading CSS chunk \d+ failed\./iu,
];
